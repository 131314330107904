import React from "react"
import Message from "../components/subscriber/message/message"
import Hero from "../sections/hero/hero"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import thanksData from "../utils/thanks-for-subscribing-ebook-queries"
import Layout from "../components/Layout"
import Ebook from "../sections/ebook/ebook"
import ebook from "../images/ebook.png"
import { getSeo } from "../utils/seo"

export default function ThankYou(props) {
  const { hero } = thanksData()
  const content = { image: ebook, alt: "great app idea", item: "FREE EBOOK" }
  return (
    <Layout>
      <Hero hero={hero} styles={hero_styles} nextSection={"message"} />
      <Ebook content={content} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = thanksData()
  return getSeo(seo)
}
