// extracted by mini-css-extract-plugin
export var appSlider = "hero_index-module--app-slider--2pgsA";
export var contentWrapper = "hero_index-module--content-wrapper--3yWZl";
export var defaultContainer = "hero_index-module--default-container--2V_Hh";
export var featureSlider = "hero_index-module--feature-slider--6a1nd";
export var float = "hero_index-module--float--1Ceh6";
export var hat = "hero_index-module--hat--1UeJ7";
export var hero = "hero_index-module--hero--3ZnTG";
export var heroArrow = "hero_index-module--hero-arrow--1mPPH";
export var heroContainer = "hero_index-module--hero-container--18J5J";
export var heroFloatingBtn = "hero_index-module--hero-floating-btn--38zmy";
export var heroFooter = "hero_index-module--hero-footer--2iSs-";
export var heroHeader = "hero_index-module--hero-header--3ctyW";
export var heroWrapper = "hero_index-module--hero-wrapper--2rIOc";
export var imageContent = "hero_index-module--image-content--3Ptx_";
export var innerPagesSlider = "hero_index-module--inner-pages-slider--1-K-2";
export var leftAppSlide = "hero_index-module--left-app-slide--O7DXv";
export var logoSlider = "hero_index-module--logo-slider--14PU2";
export var phoneAppSelect = "hero_index-module--phone-app-select--2d_-2";
export var phoneCaseSelect = "hero_index-module--phone-case-select--d0T60";
export var rightAppSlide = "hero_index-module--right-app-slide--FHM_H";
export var slickActive = "hero_index-module--slick-active--27TE9";
export var slickArrow = "hero_index-module--slick-arrow--p_rsn";
export var slickCenter = "hero_index-module--slick-center--2pDkf";
export var slickDots = "hero_index-module--slick-dots--37ady";
export var slickNext = "hero_index-module--slick-next--DcLi5";
export var slickPrev = "hero_index-module--slick-prev--3tkGO";
export var slickSlide = "hero_index-module--slick-slide--3JXqx";
export var slickSlider = "hero_index-module--slick-slider--M9MDC";
export var slickTrack = "hero_index-module--slick-track--3FJPP";
export var solvingSlider = "hero_index-module--solving-slider--1HHST";
export var titleWrapper = "hero_index-module--title-wrapper--39PoQ";