// extracted by mini-css-extract-plugin
export var advise = "ebook-module--advise--1tLWD";
export var appSlider = "ebook-module--app-slider--33zSf";
export var contact = "ebook-module--contact--3t_6L";
export var content = "ebook-module--content--2ZYun";
export var defaultContainer = "ebook-module--default-container--1YacU";
export var featureSlider = "ebook-module--feature-slider--R7vgL";
export var heading = "ebook-module--heading--2Cp10";
export var innerPagesSlider = "ebook-module--inner-pages-slider--uurCJ";
export var left = "ebook-module--left--2bdyl";
export var leftAppSlide = "ebook-module--left-app-slide--uZK_9";
export var logoSlider = "ebook-module--logo-slider--2ByHU";
export var message = "ebook-module--message--1Jsg-";
export var phoneAppSelect = "ebook-module--phone-app-select--bn8kF";
export var phoneCaseSelect = "ebook-module--phone-case-select--g7jDw";
export var right = "ebook-module--right--wKWY4";
export var rightAppSlide = "ebook-module--right-app-slide--V9UsW";
export var slickActive = "ebook-module--slick-active--2SptJ";
export var slickArrow = "ebook-module--slick-arrow--36Xg2";
export var slickCenter = "ebook-module--slick-center--lzP9P";
export var slickDots = "ebook-module--slick-dots--37rUt";
export var slickNext = "ebook-module--slick-next--1Kblx";
export var slickPrev = "ebook-module--slick-prev--3faaw";
export var slickSlide = "ebook-module--slick-slide--8_shp";
export var slickSlider = "ebook-module--slick-slider--1XglE";
export var slickTrack = "ebook-module--slick-track--1EVJr";
export var solvingSlider = "ebook-module--solving-slider--3-LBH";