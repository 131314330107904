// extracted by mini-css-extract-plugin
export var advise = "message-module--advise--3jkNS";
export var appSlider = "message-module--app-slider--Ax0FS";
export var contact = "message-module--contact--3mAqJ";
export var content = "message-module--content--3QmxA";
export var defaultContainer = "message-module--default-container--2dPBL";
export var featureSlider = "message-module--feature-slider--33uq6";
export var heading = "message-module--heading--2E-SU";
export var innerPagesSlider = "message-module--inner-pages-slider--1cimo";
export var left = "message-module--left--as_mk";
export var leftAppSlide = "message-module--left-app-slide--2M-Ne";
export var logoSlider = "message-module--logo-slider--1zKJ1";
export var message = "message-module--message--1gSum";
export var phoneAppSelect = "message-module--phone-app-select--D6628";
export var phoneCaseSelect = "message-module--phone-case-select--DkPxD";
export var right = "message-module--right--a5X05";
export var rightAppSlide = "message-module--right-app-slide--82gGC";
export var slickActive = "message-module--slick-active--VPU4Y";
export var slickArrow = "message-module--slick-arrow--235Me";
export var slickCenter = "message-module--slick-center--39AKr";
export var slickDots = "message-module--slick-dots--3f1HJ";
export var slickNext = "message-module--slick-next--2br1E";
export var slickPrev = "message-module--slick-prev--2xSoQ";
export var slickSlide = "message-module--slick-slide--3Oud9";
export var slickSlider = "message-module--slick-slider--7KrfU";
export var slickTrack = "message-module--slick-track--3QbpU";
export var solvingSlider = "message-module--solving-slider--1sszK";