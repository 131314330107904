import React from "react"
import * as styles from "./message.module.scss"
import ebook from "../../../images/ebook.png"

export default function Message() {
  return (
    <div id={"message"} className={styles.message}>
      <div className={styles.left}>
        <h2 className={styles.advise}>
          Shortly you will receive an email with instructions on how to download
          our
        </h2>
        <h1 className={styles.heading}>FREE EBOOK</h1>
        <p className={styles.content}>
          If for any reason you do not receive this email and you have checked
          your spam folder, please get in touch using the button below:
        </p>
        <button className={styles.contact}>Get in Contact</button>
      </div>
      <div className={styles.right}>
        <img src={ebook} alt={"ebook"} />
      </div>
    </div>
  )
}
