import React from "react"

interface seo {
  title: string
  description: string
  keyword: string
  image: string
  alt: string
}

// export const homepage: seo = {
//   title:
//     "Mobile App, Software and Web Application Development Agency Sydney - Devwiz",
//   description:
//     "Devwiz pioneers startups with entrepreneurs and business owners. We're a trusted Mobile App, Software and Web Application Development Agency in Sydney. Get a quote today.",
//   keyword: "Mobile App, Software and Web Application Development Agency Sydney",
// }

// export const tech_founders: seo = {
//   title: "Software Development Tech For Founders Sydney - Devwiz",
//   description:
//     "It can be difficult trying to get the right product built for an existing business. That's why we support Software Development Tech for Founders. Get a quote today.",
//   keyword: "Software Development Tech For Founders Sydney",
// }

// export const cto: seo = {
//   title: "Software Development Tech For CTOs Sydney - Devwiz",
//   description:
//     "Let Devwiz assist with your Software Development Tech for CTOs in Sydney. We'll learn about your goals and align these with your decisions. Get a quote today.",
//   keyword: "Software Development Tech For CTOs Sydney",
// }

// export const developer: seo = {
//   title: "Leading Australian App Developer - Devwiz",
//   keyword: "Leading Australian App Developer",
//   description:
//     "Searching for a Leading Australia App Developer? Devwiz is based in Sydney and has been creating web applications and mobile apps for over 7 years. Get a quote today.",
// }

// export const development: seo = {
//   keyword: "App Development Company for Web and Mobile Sydney",
//   title: "App Development Company for Web and Mobile Sydney - Devwiz",
//   description:
//     "Are you searching for an App Development Company for Web and Mobile in Australia? Devwiz are a Sydney based agency that can help you to meet your goals. Get a quote today.",
// }

// //const blogs: seo = {}

// export const business: seo = {
//   title: "Software Development Tech For Businesses Sydney - Devwiz",
//   keyword: "Software Development Tech For Businesses Sydney",
//   description:
//     "Are you looking to integrate Tech into your Business to generate additional revenue or streamline your ops? Devwiz can assist with Software Development. Get a quote today.",
// }

// export const industries: seo = {
//   title: "Industries We Provide Software Development Services - Devwiz",
//   keyword: "Industries We Provide Software Development Services",
//   description:
//     "Integrating new tech into an existing business can be challenging. We aim to provide Software Development Services for all industries. Get a quote today.",
// }

export const siteMetadata: any = {
  title: `Mobile App, Software and Web Application Development Agency Sydney - Devwiz`,
  lang: `en_US`,
  description: `Devwiz pioneers startups with entrepreneurs and business owners. We're a trusted Mobile App, Software and Web Application Development Agency in Sydney. Get a quote today.`,
  twitter: `https://twitter.com/devwizau`,
  author: `Devwiz`,
  image: `/logo.png`,
  siteUrl: `https://www.devwiz.com.au`,
  keywords: `Mobile App, Software and Web Application Development Agency Sydney`,
  alt: "Devwiz Logo",
}

export const getSeo = (seo: any) => {
  const title = seo?.meta_title ? seo.meta_title : siteMetadata.title
  const description = seo?.meta_description
    ? seo.meta_description
    : siteMetadata.description
  const keywords = seo?.keywords ? seo.keywords : siteMetadata.keywords
  const image = seo?.meta_image?.asset?.url
    ? seo.meta_image.asset.url
    : siteMetadata.image
  const alt = seo?.alt_tag ? seo.alt_tag : siteMetadata.alt
  return (
    <>
      <title>{title}</title>
      <meta name="language" content={siteMetadata.lang} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={siteMetadata.author} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:image:alt" content={alt} />
      <meta property="og:site_name" content={siteMetadata.siteUrl} />
      <meta property="og:locale" content={siteMetadata.lang} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteMetadata.siteUrl} />
      <meta name="twitter:creator" content={siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={alt} />
    </>
  )
}
